<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import router from "@/router/index.js";

export default {
  page: {
    title: "Transactions Wallet",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Transactions Wallet",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historique Wallet",
          active: true,
        },
      ],
      ordersData: [],
      transaction: {
        total: "",
        echouee: "",
        reussie: "",
      },
      totalRows: 0,
      currentPage: 1,
      perPage:100,
      currentPageA: 1,
      perPageA:100,
      keyWord: "",

      foundData: [],

      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "profil", sortable: true, label: "Profil" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "type", sortable: true, label: "Type" },
        { key: "moyen", sortable: true, label: "Moyen" },
        { key: "date", sortable: true, label: "Date paiement" },
        { key: "statut", label: "Statut" },
      ],
      fieldsA: [
        { key: "driver", sortable: true, label: "Conducteur" },
        { key: "driverPhone", sortable: true, label: "Tel conducteur" },
        { key: "dateCourse", sortable: true, label: "Date Course"},
        { key: "client", sortable: true, label: "Client" },
        { key: "clientPhone", sortable: true, label: "Téléphone" },
        { key: "type", sortable: true, label: "Moyen" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "dateTrans", sortable: true, label: "Date paiement" },
        { key: "statut", label: "Statut" },
      ],
      transactionData: [],
      transactionDataAsso: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataTransaction: [],
      recherche:null,
      totalMomo:0,
      totalTMoney:0,
      totalWaxu:0,
      totalAdmin:0,
      parBon:500
    };
  },

  async mounted() {

    this.getAssoVirements();
    const historiqueTransac = await apiRequest(
      "GET",
      "admin/wallet/info-wallet",
      undefined,
      false
    );

    if (historiqueTransac && historiqueTransac.data) {
      this.transaction.total = historiqueTransac.data.total
        ? historiqueTransac.data.total
        : "0";
      this.transaction.echouee = historiqueTransac.data.echecs
        ? historiqueTransac.data.echecs
        : "0";
      this.transaction.reussie = historiqueTransac.data.reussies
        ? historiqueTransac.data.reussies
        : "0";
    }

    const listeTransaction = await apiRequest(
      "GET",
      "admin/wallet/find-wallet",
      undefined,
      false
    );

    if (listeTransaction && listeTransaction.data) {
      this.totalValue=listeTransaction.data.transacsTotal
      this.totalMomo=listeTransaction.data.windrawByMoMo
      this.totalTMoney=listeTransaction.data.windrawByTMoney
      this.totalWaxu=listeTransaction.data.windrawByWaxu
      this.totalAdmin=listeTransaction.data.windrawByAdmin
    
      const TransactListTable = listeTransaction.data.transacs.map((transaction) => {
        if (transaction.client!=null) {
          return {
            id:  transaction.client.id,
            profil:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.tel &&
              transaction.client.personne.tel != ""
                ? transaction.client.personne.indicatifTel +
                  transaction.client.personne.tel
                : "",
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.montant,
            type: transaction.type,
            moyen: transaction.moyen,
            statut: transaction.statut == "PAYER" ? "PAID" : transaction.statut,
          };
        }
      });
    TransactListTable.forEach(element => {
      if(element!=null)this.transactionData.push(element)
    });
    this.totalRows = this.transactionData.length;
      console.log( this.transactionData);
    }
  },

  methods: {

    async getAssoVirements(){

      const req = await  apiRequest('GET','admin/wallet/asso/course');

      if (req && req.status == 200){
        console.log('req.data', req.data);
        this.transactionDataAsso = req.data.transacs.map(el => {

          return{
            driver:el.conducteur.personne.lastname +" "+el.conducteur.personne.firstname,
            client:el.client.personne.lastname +" "+el.client.personne.firstname,
            driverPhone: el.conducteur.personne.indicatifTel +" "+el.conducteur.personne.tel,
            clientPhone: el.client.personne.indicatifTel +" "+el.client.personne.tel,
            waTel: (el.conducteur.personne.indicatifTel +el.conducteur.personne.tel).replace(/\s/g,""),
            waClientTel: (el.client.personne.indicatifTel +el.client.personne.tel).replace(/\s/g,""),
            amount:el.montant,
            dateCourse: el.course.dateCreation ? new Date(el.course.dateCreation).toLocaleString("fr-FR"): "Date invalide",
            type:el.course.moyenTransport,
            //moyen:el.conducteur.personne.lastname +" "+el.conducteur.personne.firstname,
            dateTrans: el.datePaiement ? new Date(el.datePaiement).toLocaleString("FR-fr") : "Date invalide",
            statut: el.statut,
            montant:el.montant ?  el.montant  : " Aucune valeur trouvée",
          }
        })
      }

    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async actif(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "admin/wallet/find-wallet?page=" + page + "&limit=" + limit,
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {

        const TransactListTableActif = listeWallet.data.transacs.map((transaction) => {
        if (transaction.client!=null) {
          console.log(transaction.client)
          return {
            id: transaction.client.id,
            profil:transaction.client.personne?
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname:"",
            tel:
              transaction.client.personne.tel &&
              transaction.client.personne.tel != ""
                ? transaction.client.personne.indicatifTel +
                  transaction.client.personne.tel
                : "",
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.montant,
            type: transaction.type,
            moyen: transaction.moyen,
            statut: transaction.statut == "PAYER" ? "PAID" : transaction.statut,
          };
        }
      });
      this.newDataTransaction=TransactListTableActif
      this.newDataTransaction=[]
      TransactListTableActif.forEach(element => {
      if(element!=null)this.newDataTransaction.push(element)
    });


    this.totalRows = this.newDataTransaction.length;
    this.userChargeLoader = false;
      }
    },

  /*   async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    }, */

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/wallet/transaction-recherche?search="+this.recherche+"&page="+ this.startValue + "&limit=" + this.endValue,
        undefined,


        false
      );
      if (dataSearch && dataSearch.data) {
        const TransactListTable = dataSearch.data.map((transaction) => {
        if (transaction.client) {
          return {
            id: transaction.client.id,
            profil:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.tel &&
              transaction.client.personne.tel != ""
                ? transaction.client.personne.indicatifTel +
                  transaction.client.personne.tel
                : "",
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.montant,
            type: transaction.type,
            moyen: transaction.moyen,
            statut: transaction.statut == "PAYER" ? "PAID" : transaction.statut,
          };
        }
      });
        this.newDataTransaction = TransactListTable;
        this.totalRows = TransactListTable.length;

        this.userChargeLoader = false;
      }
    },

    async rechercheA() {

this.keyWord = this.keyWord.toLowerCase()

this.foundData = this.transactionDataAsso.filter(data => data.driver.toLowerCase().includes(this.keyWord) || data.driverPhone.includes(this.keyWord)
  )


console.log("found ", this.foundData)
},

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 100;
      this.endValue -= 100;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          
         
          <div class="card-body">
           
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-2">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-5">Total des transactions</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ transaction.total }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-2">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-info"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Total des transactions réussies</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ transaction.reussie }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-2">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-warning"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Total des transactions échouées</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ transaction.echouee }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-success"
                  class="border border-success"
                >
                  <div>
                    <ul>
                      <li>
                        <span
                          class="text-uppercase"
                          style="color: black; font-weight: bold"
                          >PAID :</span
                        >
                        Transaction réussie
                      </li>
                      <li>
                        <span
                          class="text-uppercase"
                          style="color: black; font-weight: bold"
                          >Failed / ERROR :</span
                        >
                        Solde insuffisant ou transaction rejetée ou numéro
                        invalide
                      </li>
                      <li>
                        <span
                          class="text-uppercase"
                          style="color: black; font-weight: bold"
                          >Initial :</span
                        >
                        Paiment initialisé
                      </li>
                    </ul>
                  </div>
                  <p>
                    Le statut
                    <span
                      class="text-uppercase"
                      style="color: black; font-weight: bold"
                      >Failed</span
                    >
                    ne signifie pas de manière systématique que la transaction a
                    échoué. Cela voudrait simplement dire que la dernière valeur
                    enregistrée du statut suite à la vérification est
                    <span
                      class="text-uppercase"
                      style="color: black; font-weight: bold"
                      >failed</span
                    >
                  </p>
                </b-card>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-3">Total de recharges via MoMo</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ totalMomo }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-info"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-3">Total de recharges via TMoney</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ totalTMoney }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-warning"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-3">Total de recharges via Waxu</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ totalWaxu }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-danger"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-3">Total de recharges par admin</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ totalAdmin }}</h1>
                  </div>
                </b-card>
              </div>
             </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="transactionData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
           
            
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                    <a class="font-weight-bold active">Historique globale {{totalRows}} </a>
                </template>
                <div class="row d-flex justify-content-between align-items-center  mt-4">
                  <div class="p-3 h-10">

                    <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
            <!-- <div>Total d'historiques : {{ totalRows }}</div> -->
             
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
                   
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                <div class="input ">
                    <input
                      type="search"
                      id="serchInput"
                      placeholder="Rechercher..."
                      v-model="recherche"
                      @keyup.enter="userSearch"
                    />
                  </div>
      
              </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="newDataTransaction.length!=0?newDataTransaction: transactionData"
                    :fields="fields"
                    responsive="sm"
       
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(statut)="row">
                     <!--  <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger':
                            `${row.value}` === 'ERROR'
                              ? 'badge-soft-danger'
                              : `${row.value}` === 'FAILED',
                          'badge-soft-success': `${row.value}` === 'PAID',
                          'badge-soft-warning': `${row.value}` === 'INITIAL',
                        }"
                      >
                        {{ row.value }}
                      </div> -->

                 
                        <span
                          v-if="row.item.statut == 'PAID'"
                          style="
                            padding: 0.25rem 1.2rem;
                            background-color: #4caf50;
                            border-radius: 1.3rem;
                            width: 100% !important;
                            height: 14px;
                            font-size: 13px;
                            color: #fff;
                          "
                        >
                          PAYÉ
                        </span>
                        <span
                          v-if="row.item.statut == 'ECHEC'"
                          style="
                            padding: 0.25rem 0.89rem;
                            background-color: #D01E3E;
                            border-radius: 1.3rem;
                            width: 100%;
                            height: 16px;
                            font-size: 13px;
                            color: #fff;
                          "
                        >
                          ÉCHOUÉ
                        </span>
                       

                        <span
                          v-if="row.item.statut == 'ATTENTE'"
                          style="
                            padding: 0.25rem 0.89rem;
                            background-color: #ff8c00;
                            border-radius: 1.3rem;
                            width: 100%;
                            height: 16px;
                            font-size: 13px;
                            color: #fff;
                          "
                        >
                          ATTENTE
                        </span>
              
                    </template>
                    <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <transition mode="out-in">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <li>
                        <span class="interval"> {{ startValue }}</span> -
                        <span class="interval">{{ endValue }}</span> sur
                        {{ totalValue }}
                      </li>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <li>
                        <button
                          class="myBouton"
                          @click="firstVal()"
                          :disabled="startValue == 1"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Prem"
                        >
                          <ion-icon name="play-skip-back-outline"></ion-icon>
                        </button>
                        <button
                          type="button"
                          class="myBouton"
                          @click="decrement()"
                          :disabled="endValue == parBon"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Préc"
                        >
                          <ion-icon name="chevron-back-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="increment()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Suiv"
                        >
                          <ion-icon name="chevron-forward-outline"></ion-icon>
                        </button>
                        <button
                          class="myBouton"
                          @click="incrementToLast()"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Dern"
                        >
                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
              </b-tab>
              <b-tab title-link-class="p-3">

                <template v-slot:title>
                    <a class="font-weight-bold active">Virements Asso {{transactionDataAsso.length || "0"}} </a>
                </template>
                
                    <div class="row d-flex justify-content-between align-items-center  mt-4">
                  <div class="p-3 h-10">

                    <div class="row justify-content-end">
                    <b-pagination v-model="currentPageA" :total-rows="transactionDataAsso.length" :per-page="perPageA" :limit="2"
                      first-text="◀" last-text="▶" aria-controls="my-table"></b-pagination>
                  </div>
                   
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                <div class="input ">
                    <input
                      type="search"
                      id="serchInput"
                      placeholder="Rechercher..."
                      v-model="keyWord"
                      @input="rechercheA()"
                    />
                  </div>
      
              </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="foundData.length!=0 ? foundData : transactionDataAsso"
                    :fields="fieldsA"
                    responsive="sm"
       
                    :current-page="currentPageA"
                    :per-page="perPageA"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(statut)="row">
                     
                        <span
                          v-if="row.item.statut == 'PAID'"
                          style="
                            padding: 0.25rem 1.2rem;
                            background-color: #4caf50;
                            border-radius: 1.3rem;
                            width: 100% !important;
                            height: 14px;
                            font-size: 13px;
                            color: #fff;
                          "
                        >
                          PAYÉ
                        </span>
                        
                        <span
                          v-if="row.item.statut == 'PENDING'"
                          style="
                            padding: 0.25rem 0.89rem;
                            background-color: #ff8c00;
                            border-radius: 1.3rem;
                            width: 100%;
                            height: 16px;
                            font-size: 13px;
                            color: #fff;
                          "
                        >
                          ATTENTE
                        </span>
              
                    </template>
                    <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                    <template v-slot:cell(driverPhone)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + (row.value.replace('+','')).replace(/\/s/g,'')"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(clientPhone)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + (row.value.replace('+','')).replace(/\/s/g,'')"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
              <div class="col">
                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                <div class="d-flex justify-content-end">

                  <b-pagination v-model="currentPageA" :total-rows="transactionDataAsso.length" :per-page="perPageA" :limit="2"
                  first-text="◀" last-text="▶" aria-controls="my-table"></b-pagination>

                </div>
               
              </div>
            </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
  

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}


ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}
/*   Pagination System   */

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

</style>
